<template>
  <sub-page
      v-model="$store.state.settings.pageData"
      :title="$t('Comments')"
      icon="mdi-forum"
      fill-height
  >
    <div v-if="!loading" class="fill-height">
      <div class="mt-6" v-if="entity.modules.length > 0">
        <v-sheet v-for="(module,i) in entity.modules" :key="i + 'semester'" :ref="'module_' + i" class="wsRounded ">
          <!-- Module-->
          <v-sheet class="wsRoundedLightTopSemi py-1 px-4" dark :color="wsACCENT">
            <h4> {{ module.name }}</h4>
          </v-sheet>
          <v-sheet :style="`border : 1px solid ${wsBACKGROUND}`" class="wsRoundedLightBottom mb-5">
            <template  v-for="(topic,j) in module.topics">
              <v-divider
                  v-if="j > 0"
                  :key="'topic.divider' + i + j"
                  :style="`border-color : ${wsBACKGROUND}`"
              />

              <h5 class="px-4 py-2" :key="'topic.name' + i + j">
                {{ topic.name }}
              </h5>

              <template v-for="(component, k) in topic.components">
                <v-hover v-slot="{hover}"  :key="'component' + i + j + k ">
                  <router-link
                      :to="businessDashLink('courses/' + uuid + '/comments/' + component.uuid)"
                      class="noUnderline pointer"
                      style="color: black "
                  >
                    <v-sheet :color="hover ? wsLIGHTCARD : null">

                      <v-divider  :style="`border-color : ${wsBACKGROUND}`" />

                      <div class="d-flex align-center justify-space-between px-4 py-2">
                        <div class="d-flex align-center" >
                          <v-icon class="mr-3"  :color="wsATTENTION" > {{ getEntityTypeIcon(component.entity_type ) }}</v-icon>
                          <h5  :style="`color : ${wsACCENT}`"  class="hoverUnderline"  v-html="component.name"></h5>
                        </div>
                        <div class="d-flex align-center">

                          <v-chip v-if="component.comments"
                                  :color="wsATTENTION"
                                  class="mr-3"
                          >
                            <h5 class="white--text">+{{ component.comments }}</h5>
                          </v-chip>

                          <v-icon :color="wsATTENTION" >mdi-arrow-right</v-icon>
                        </div>

                      </div>

                    </v-sheet>
                  </router-link>
                </v-hover>

              </template>

            </template>



          </v-sheet>
        </v-sheet>
      </div>
      <div v-else
           class="wsRoundedLight
         d-flex
         fill-height
         justify-center
         align-center
         pb-16 pt-8 py-6 " >

        <v-sheet  width="600">

          <div class="d-flex justify-center">
            <v-icon size="80" :color="wsDARKLIGHT" >mdi-text-box-check-outline</v-icon>
          </div>
          <h3 style="font-size: 22px; font-weight: 700" class="text-center  my-6">{{ $t('NoTasksMessage') }}</h3>
          <div v-if="MODULE_PERMISSIONS(['MODULE_WESTUDY__EDITOR'], true)"
               class="d-flex justify-center">
            <v-btn height="40" :to="businessDashLink('courses/' + this.uuid + '/editor')" dark :color="wsATTENTION" class="noCaps">
              {{ $t('CourseEditor') }}
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </v-sheet>

      </div>
    </div>
    <v-sheet v-else class="fill-height d-flex align-center justify-center">
      <v-progress-circular indeterminate :color="wsACCENT" />
    </v-sheet>

  </sub-page>

</template>

<script>
import {mapActions, mapMutations} from "vuex";

export default {
  name: "evaluationComponents",
  props : {
    uuid : {
      type : String,
      default : ''
    }
  },
  data() {
    return {
      loading : true,
      entity : {
        modules : []
      }
    }
  },
  watch : {
    course(val) {
      this.entityData = val
      this.INIT_PAGE({
        icon : 'mdi-forum',
        title : this.$t('Comments'),
      })
    },
  },
  methods : {
    ...mapMutations('courses' , ['INIT_PAGE']),
    ...mapActions('courses'   , ['GET_COURSE_COMMENTS_COMPONENTS']),
    getEntityTypeIcon( type ) {

      let icon = ''
      switch ( type ) {
        case 'lecture'    :  icon = 'mdi-book-open'       ; break;
        case 'task'       :  icon = 'mdi-content-paste'                ; break;
        case 'task_video' :  icon = 'mdi-cast-education'               ; break;
        case 'test'       :  icon = 'mdi-order-bool-ascending-variant' ; break;
        case 'task_test'  :  icon = 'mdi-order-bool-ascending-variant' ; break;
        case 'video'      :  icon = 'mdi-video'                        ; break;
        case 'file'       :  icon = 'mdi-file'                         ; break;
        default : icon = 'mdi-bookmark'
      }
      return icon

    },
    getEntityTypeIconColor( component ) {

      let type = component.entity_type

      if ( type === 'task_test' && component.hasTest ) {
        return this.wsATTENTION
      }
      if ( type === 'video' && !component.video && component.file ) {
        return this.wsATTENTION
      }

      if ( type === 'task_video' ) { type = 'task' }
      if ( type === 'file' ) { type = 'file_lang' }
      return component[type] ? this.wsATTENTION : 'grey'
    },

  },
  async mounted() {
    this.INIT_PAGE({
      icon : 'mdi-forum',
      title : this.$t('Comments'),
    })
    let result = await this.GET_COURSE_COMMENTS_COMPONENTS(this.uuid)
    if ( !result ) {
      this.loading = false
      return
    }
    result.modules.forEach(module => {
      module.topics = module.topics.filter( el=>el.components.length > 0 )
    })
    result.modules = result.modules.filter( el => el.topics.length > 0)
    this.entity = result
    this.loading = false
  }
}
</script>

<style scoped>
.hoverUnderline:hover {
  color : #567186 !important;
  text-decoration: underline !important;
}
</style>